import {
    argent,
    bitkeep,
    coinbase,
    injectedEVM,
    mathwallet,
    metaMask,
    okx,
    omni,
    onto,
    phantom,
    rainbow,
    safepal,
    tokenpocket,
    trust,
    walletconnect,
} from '../providers/connectors';
import type { SolanaWalletConfig } from '../providers/connectors/wallet-adapter';
import type { WalletConnectOptions } from '../providers/connectors/wallet-connect-v2';
import { BITKEEPWALLET, COINBASE, MATHWALLET, METAMASK, SAFEPAL, TOKENPOCKET, TRUST } from '../providers/injected';
import type { Wallet } from '../types';
import { getInjectedProvider } from './provider-utils';

export function evmWallets(options: Omit<WalletConnectOptions, 'chains'>): Wallet[] {
    const wallets = [
        metaMask({ ...options, showQrModal: false }),
        walletconnect(options),
        rainbow({ ...options, showQrModal: false }),
        trust({ ...options, showQrModal: false }),
        coinbase(),
        argent({ ...options, showQrModal: false }),
        omni({ ...options, showQrModal: false }),
        okx({ ...options, showQrModal: false }),
        bitkeep({ ...options, showQrModal: false }),
        mathwallet({ ...options, showQrModal: false }),
        safepal({ ...options, showQrModal: false }),
        tokenpocket({ ...options, showQrModal: false }),
        onto({ ...options, showQrModal: false }),
    ];
    const providerInfos = getInjectedProvider();
    if (providerInfos && providerInfos.length > 0) {
        providerInfos
            .filter((providerInfo) => {
                return (
                    providerInfo.check != TOKENPOCKET.check &&
                    providerInfo.check != SAFEPAL.check &&
                    providerInfo.check != MATHWALLET.check &&
                    providerInfo.check != BITKEEPWALLET.check &&
                    providerInfo.check != METAMASK.check &&
                    providerInfo.check != TRUST.check &&
                    providerInfo.check != COINBASE.check
                );
            })
            .forEach((providerInfo) => {
                wallets.unshift(
                    injectedEVM({
                        id: providerInfo.id,
                        name: providerInfo.name,
                        iconUrl: providerInfo.logo,
                    })
                );
            });
    }
    return wallets;
}

export function solanaWallets(config?: SolanaWalletConfig): Wallet[] {
    const wallets = [phantom()];
    return wallets;
}

export function evmInjectedWallet(): Wallet[] | undefined {
    const providerInfos = getInjectedProvider();
    if (providerInfos && providerInfos.length > 0) {
        return providerInfos.map((providerInfo) =>
            injectedEVM({
                id: providerInfo.id,
                name: providerInfo.name,
                iconUrl: providerInfo.logo,
            })
        );
    }
    return undefined;
}
