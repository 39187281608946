export default {
    language: '日本',
    ConnectButton: {
        connectWallet: 'ウォレットの接続',
        wrongNetwork: 'ネットワークエラー',
    },
    ConnectModal: {
        email: 'メールボックス',
        phone: '電話番号',
        google: 'Google',
        apple: 'Apple',
        facebook: 'Facebook',
        microsoft: 'Microsoft',
        linkedin: 'Linkedin',
        github: 'Github',
        discord: 'Discord',
        twitter: 'Twitter',
        twitch: 'Twitch',
        title1: '財布とは',
        title2: 'ウォレットの接続',
        tip1: 'トークンとNFTのセキュリティをすべて保護',
        tip2: '送信、受信、表示などのデジタル資産を管理できます。',
        tip3: '新しいログイン方法',
        tip4: 'ソーシャルアカウントからログインすることができ、暗号化されたウォレットは自動的に生成されますが、簡単ですが安全です。',
        less: 'やめる',
        more: '詳細',
    },
    ConnectForm: {
        loading1: '接続の確認',
        loading2: 'スキャン2次元コード',
        copied_to_clipboard: 'クリップボードにコピー',
        copy_success: 'コピー成功',
    },
    ChainModal: {
        title: 'ネットワークの切り替え',
        wrong: 'ネットワークエラーが検出されました。切り替えまたは切断後に続行してください。',
        connected: '接続済み',
        confirm: '財布の中で確認する',
        disconnect: '接続解除',
        loading: 'ロード中',
        switchTip:
            'ウォレットでは、このアプリケーションからネットワークを切り替えることはサポートされていません。財布の中でネットワークを切り替えてみてください。',
    },
    AccountModal: {
        copied: 'コピー済み',
        logout: 'ログオンの終了',
        wallet: '財布',
        buy: 'デジタル通貨の購入',
        copy_address: 'アドレスのコピー',
    },
};
