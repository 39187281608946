import React, { useEffect, useState } from 'react';
import '../../styles/index.css';
import './index.css';
import { toBase58Address } from '@particle-network/auth';
import Avatar from '../../services/AuthAvatar';
import { useParticleConnect } from '../../hooks/useParticleConnect';
import { ConnectButtonRenderer } from './ConnectButtonRenderer';
import { useAccount } from '../../hooks/useAccount';
import {
    useAccountModal,
    useConnectModal,
    useAccountInfo,
    useModalState,
    useChainModal,
    useCurrentChain,
    useUnsupportedChain,
} from '../../hooks';

import { useTranslation } from '../../provider/I18nContext';
import { shortString } from '../../utils';
import AccountModal from '../AccountModal';
import type { ChainInfo } from '@particle-network/connect';

export interface ConnectButtonProps {
    modalMode?: string;
}
function ConnectButton({ modalMode }: ConnectButtonProps) {
    const [isUIButton, setIsUIbutton] = useState(false);
    const { cacheconnect } = useParticleConnect();
    const account = useAccount();
    const { openConnectModal } = useConnectModal();
    const { openAccountModal, closeAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const { accountModalOpen } = useModalState();
    const chain = useCurrentChain();
    const { accountLoading } = useAccountInfo();
    const { unsupportedChain } = useUnsupportedChain();
    const { t } = useTranslation();

    const openAccount = () => {
        setIsUIbutton(true);
        openAccountModal?.();
    };
    const closeAccount = () => {
        setIsUIbutton(false);
        closeAccountModal?.();
    };

    console.log('chain:', chain);

    useEffect(() => {
        cacheconnect();
    }, []);

    const accountFilter = (ant: string | undefined) => {
        if (!ant) return '';
        let account = ant;
        if (chain?.name.toLowerCase() === 'tron') {
            console.log('tron name filter');
            account = toBase58Address(ant);
        }

        return shortString(account);
    };
    if (accountLoading) {
        return <></>;
    }
    if (unsupportedChain) {
        return (
            <div className="particle-unsupported-chain-button  center-center" onClick={openChainModal}>
                <span> {t('ConnectButton.wrongNetwork')}</span> <div className="particle-icon-more"></div>
            </div>
        );
    }

    const getChainDisplayName = (chainInfo?: ChainInfo) => {
        if (!chainInfo) {
            return '';
        }
        if (chainInfo.network === 'Mainnet') {
            return chainInfo.fullname.replace(' Mainnet', '');
        } else {
            return chainInfo.fullname;
        }
    };

    if (accountLoading) {
        return <></>;
    }
    if (unsupportedChain) {
        return (
            <div className="particle-unsupported-chain-button  center-center" onClick={openChainModal}>
                <span> {t('ConnectButton.wrongNetwork')}</span> <div className="particle-icon-more"></div>
            </div>
        );
    }

    return (
        <div className={`center-center particle-user-bar-container connect-body ${isUIButton ? 'is-ui-button' : ''}`}>
            {account ? (
                <>
                    <div className="particle-account-info center-center particle-chain" onClick={openChainModal}>
                        <div className="particle-avatar-img" style={{ backgroundImage: `url(${chain?.icon})` }}>
                            {/* <img className="particle-chain-icon" src={chain?.icon} alt="" /> */}
                        </div>
                        <span>{getChainDisplayName(chain)}</span>
                        <div className="particle-icon-more"></div>
                    </div>
                    <div className="particle-account-info-fixed">
                        <div
                            className="particle-account-info center-center"
                            onClick={() => (accountModalOpen ? closeAccount?.() : openAccount?.())}
                        >
                            <div className="particle-avatar-img">
                                <Avatar opts={{ seed: account, size: 24, scale: 1 }} />
                            </div>
                            <span>{accountFilter(account)}</span>
                            <div className="particle-icon-more"></div>
                        </div>
                        <AccountModal onClose={closeAccount} open={accountModalOpen} mode={modalMode} />
                    </div>
                </>
            ) : (
                <div className={'particle-connect-wallet-btn'} onClick={openConnectModal}>
                    {t('ConnectButton.connectWallet')}
                </div>
            )}
        </div>
    );
}
export default ConnectButton;

ConnectButton.Custom = ConnectButtonRenderer;
