import React, { createContext, useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_US from '../locales/lang/en_US';
import zh_CN from '../locales/lang/zh_CN';
import ko_KR from '../locales/lang/ko_KR';
import ja_JP from '../locales/lang/ja_JP';
import zh_TW from '../locales/lang/zh_TW';

const resources = {
    en: {
        translation: en_US,
    },
    'zh-CN': {
        translation: zh_CN,
    },
    ko: {
        translation: ko_KR,
    },
    ja: {
        translation: ja_JP,
    },
    'zh-TW': {
        translation: zh_TW,
    },
    'zh-HK': {
        translation: zh_TW,
    },
};

const i18nextInstance = i18n.createInstance();

i18nextInstance.init({
    fallbackLng: 'en',
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

i18nextInstance.use(initReactI18next);

const I18nContext = createContext(i18nextInstance);

const useTranslation = () => {
    const context = useContext(I18nContext);
    return {
        ...context,
        i18n: i18nextInstance,
    };
};

interface IProps {
    children: React.ReactNode;
}

const I18nProvider = (props: IProps) => {
    return <I18nContext.Provider value={i18nextInstance}>{props.children}</I18nContext.Provider>;
};

export { I18nProvider, useTranslation };
