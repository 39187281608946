import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import React from 'react';
import {
    useAccountInfo,
    useAccountModal,
    useChainModal,
    useConnectModal,
    useModalState,
} from '../../provider/ModalContext';
import { useAccount } from '../../hooks/useAccount';
import { useParticleConnect } from '../../hooks/useParticleConnect';
import type { ChainInfo } from '@particle-network/connect';
import { toBase58Address } from '@particle-network/auth';
import { useNetwork } from '../../hooks';
import { shortString } from '../../utils';
import AccountModal from '../AccountModal';
import './index.css';

const noop = () => {};

export interface ConnectButtonRendererProps {
    children: (renderProps: {
        account?: string;
        chain?: ChainInfo;
        openChainModal?: () => void;
        openAccountModal?: () => void;
        openConnectModal?: () => void;
        closeAccountModal?: () => void;
        accountModalOpen?: boolean;
        connectModalOpen?: boolean;
        accountStr?: string;
        accountLoading?: boolean;
    }) => ReactNode;
}

export function ConnectButtonRenderer({ children }: ConnectButtonRendererProps) {
    const account = useAccount();

    const [isCustomButton, setIsCustonButton] = useState(false);

    const { cacheconnect } = useParticleConnect();
    const { openConnectModal } = useConnectModal();
    const { openAccountModal, closeAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const { accountModalOpen, connectModalOpen } = useModalState();
    const { accountLoading } = useAccountInfo();
    const { chain } = useNetwork();

    const accountFilter = (ant: string | undefined) => {
        if (!ant) return '';
        let account = ant;
        if (chain?.name.toLowerCase() === 'tron') {
            account = toBase58Address(ant);
        }
        return shortString(account);
    };

    useEffect(() => {
        cacheconnect();
    }, []);

    const openAccount = () => {
        setIsCustonButton(true);
        openAccountModal?.();
    };
    const closeAccount = () => {
        setIsCustonButton(false);
        closeAccountModal?.();
    };

    return (
        <div className={`custom-btn-box ${isCustomButton ? 'account-show' : ''}`}>
            {children({
                chain,
                account,
                accountStr: accountFilter(account),
                accountModalOpen,
                connectModalOpen,
                openChainModal: openChainModal ?? noop,
                openAccountModal: openAccount ?? noop,
                closeAccountModal: closeAccount ?? noop,
                openConnectModal: openConnectModal ?? noop,
                accountLoading,
            })}
            <AccountModal onClose={closeAccount} open={accountModalOpen} />
        </div>
    );
}

ConnectButtonRenderer.displayName = 'ConnectButton.Custom';
