import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import '../../styles/index.css';
import './index.css';
import {
    useConnectKit,
    useInitialChainId,
    useNetwork,
    useUnsupportedChain,
    useParticleConnect,
    useSwitchChains,
} from '../../hooks';

import type { Chain, ChainInfo } from '@particle-network/connect';
import { useTranslation } from '../../provider/I18nContext';

export interface ChainModalProps {
    open: boolean;
    chains?: Chain[];
    onClose?: () => void;
}
export function ChainModal({ open, onClose, chains }: ChainModalProps) {
    const { t } = useTranslation();
    const [switchLoading, setSwitchLoading] = useState(0);
    const CurrentChainId = useInitialChainId();
    const network = useNetwork();
    const connectKit = useConnectKit();
    const { unsupportedChain } = useUnsupportedChain();
    const { disconnect } = useParticleConnect();
    const isSwitch = useMemo(() => {
        if (!network || !network.connectId) return -1;
        if (network.connectId === 'particle') return 1;
        const connector = connectKit?.getConnector(network.connectId)?.connector;
        // @ts-ignore
        if (connector && network.connectId && !!connector?.switchChain) {
            return 1;
        } else {
            return -1;
        }
    }, [network.connectId, connectKit]);

    const { renderChains } = useSwitchChains();

    const modalCloseHandle = () => {
        onClose && onClose();
    };

    const logout = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        await disconnect();
        onClose && onClose();
    }, []);

    const [overflow, setOverflow] = useState<string[]>([]);

    useEffect(() => {
        if (open) {
            const htmlOverFlow = document.getElementsByTagName('html')[0].style.overflow;
            const bodyOverFlow = document.getElementsByTagName('body')[0].style.overflow;
            setOverflow([htmlOverFlow, bodyOverFlow]);
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('html')[0].style.overflow = overflow[0];
            document.getElementsByTagName('body')[0].style.overflow = overflow[1];
            setOverflow([]);
        }
    }, [open]);

    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;
    };

    const switchLocalChain = useCallback(
        async (e, chain: Chain) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if (network.isSolana && network.connectId !== 'particle') return;
            if (network.chain?.id === chain.id) throw new Error('The chain is same as the current chain');
            try {
                setSwitchLoading(chain.id);
                await connectKit.switchChain({ id: chain.id, name: chain.name });
            } catch (error) {
                console.error('switchChain', error);
                // setErrorMsg(error && error.toString());
            } finally {
                setSwitchLoading(0);
            }
        },
        [network]
    );

    const getChainDisplayName = (chainInfo: ChainInfo) => {
        if (!chainInfo) {
            return '';
        }
        if (chainInfo.network === 'Mainnet') {
            return chainInfo.fullname.replace(' Mainnet', '');
        } else {
            return chainInfo.fullname;
        }
    };

    return (
        <>
            {open ? (
                <>
                    {/* masker */}
                    <div
                        className={'particle-chain-modal-container center-center show'}
                        onClick={modalCloseHandle}
                        onTouchStart={stopPropagation}
                        onScroll={stopPropagation}
                    >
                        <div
                            className={'particle-chain-modal-box show'}
                            onTouchStart={stopPropagation}
                            onScroll={stopPropagation}
                        >
                            <div className="particle-chain-modal-close hover" onClick={modalCloseHandle}></div>
                            <div className="particle-modal-title">{t('ChainModal.title')}</div>
                            {unsupportedChain && <div className="unsupported-tips">{t('ChainModal.wrong')}</div>}

                            {isSwitch === 1 || unsupportedChain ? (
                                <div className="particle-chain-list-box">
                                    {renderChains.map((chain) => (
                                        <div
                                            key={`${chain.name}-${chain.id}`}
                                            className={`particle-chain-list-item center-start ${
                                                CurrentChainId === chain.id ? 'active' : ''
                                            } ${
                                                network.isSolana && network.connectId !== 'particle' ? '' : 'item-hover'
                                            }`}
                                            onClick={(e) => switchLocalChain(e, chain)}
                                        >
                                            <img src={chain?.icon} alt="" />
                                            <div className={'flex1'}>{getChainDisplayName(chain)}</div>

                                            {CurrentChainId === chain.id ? (
                                                <div className="particle-chain-active-connected center-end">
                                                    {t('ChainModal.connected')} <span></span>
                                                </div>
                                            ) : switchLoading === chain.id ? (
                                                <div className="particle-chain-active-confirm center-end">
                                                    {t('ChainModal.confirm')} <span></span>
                                                </div>
                                            ) : undefined}
                                        </div>
                                    ))}
                                    {unsupportedChain && (
                                        <div onClick={logout} className="particle-disconnect-btn center-center">
                                            {t('ChainModal.disconnect')}
                                        </div>
                                    )}
                                </div>
                            ) : isSwitch === -1 ? (
                                <div className="particle-chain-list-box particle-not-tips">
                                    {t('ChainModal.switchTip')}
                                </div>
                            ) : (
                                <div>{t('ChainModal.loading')}</div>
                            )}
                        </div>
                    </div>
                </>
            ) : undefined}
        </>
    );
}

export default memo(ChainModal);
