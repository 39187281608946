import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';
import { decode58, encode58 } from './base58';

const sha256Hex = (msg: string) => CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(CryptoJS.enc.Hex.parse(msg)));

export const toBase58Address = (hex: string): string => {
    const addr = `41${hex.substring(2)}`;
    const doubleSha256 = sha256Hex(sha256Hex(addr));
    const checkSum = doubleSha256.substring(0, 8);
    const address = Buffer.from(addr + checkSum, 'hex');

    return encode58(address);
};

export const toHexAddress = (base58Sting: string): string => {
    if (base58Sting.length <= 4) throw new Error('Invalid address provided');

    let address = Buffer.from(decode58(base58Sting)).toString('hex');

    const checkSum = address.substring(address.length - 8, address.length);
    address = address.substring(0, address.length - 8);

    const checkSum1 = sha256Hex(sha256Hex(address)).substring(0, 8);

    if (`${checkSum}` === `${checkSum1}`) return `0x${address.substring(2)}`;

    throw new Error('Invalid address provided');
};
