import type { Chain, ChainInfo } from '@particle-network/chains';
import { ParticleChains } from '@particle-network/chains';

export function getEVMChainById(chainId: number): Chain | undefined {
    console.log('getEVMChainById:', chainId);
    const chainType = 'evm';
    const evnChains = Object.keys(ParticleChains)
        .filter((key) => ParticleChains[key].chainType === chainType)
        .map((key) => ParticleChains[key]);
    const chain = evnChains.find((item) => item.id === chainId);
    if (chain) {
        return chain;
    }
    return undefined;
}

export function getSolanaChainById(chainId: number): Chain | undefined {
    const chainType = 'solana';
    const solanaChains = Object.keys(ParticleChains)
        .filter((key) => ParticleChains[key].chainType === chainType)
        .map((key) => ParticleChains[key]);
    const chain = solanaChains.find((item) => item.id === chainId);
    if (chain) {
        return chain;
    }
    return undefined;
}

export function isChainSupported(chain: Chain): boolean {
    const chainKey = Object.keys(ParticleChains).find((key) => ParticleChains[key]?.id === chain.id);
    return !!chainKey;
}

export function getChainInfo(chain: Chain): ChainInfo | undefined {
    const chainKey = Object.keys(ParticleChains).find((key) => ParticleChains[key]?.id === chain.id);
    if (!chainKey) return undefined;
    const chainInfo = ParticleChains[chainKey];
    return chainInfo;
}
