const style = `
.particle-wallet-entry-container .particle-pwe-btn {
  background: none;
  border: none;
  cursor: pointer;
  height: 60px;
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  width: 60px;
  -webkit-box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 60px;
}
.particle-wallet-entry-container .particle-pwe-btn:not(.is-dragging) {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.particle-wallet-entry-container .particle-pwe-btn > img {
  height: 100%;
  width: 100%;
}
.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-wallet-icon {
  display: block;
}
.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-wallet-icon:not(.particle-pwe-wallet-icon-hide) {
  -webkit-animation: particle-pwe-wallet-icon-show 0.3s ease-in-out;
          animation: particle-pwe-wallet-icon-show 0.3s ease-in-out;
}
@-webkit-keyframes particle-pwe-wallet-icon-show {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes particle-pwe-wallet-icon-show {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-wallet-icon.particle-pwe-wallet-icon-hide {
  display: none;
}
.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-down-arrow {
  display: block;
}
.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-down-arrow:not(.particle-pwe-down-arrow-hide) {
  -webkit-animation: particle-pwe-down-arrow-show 0.3s ease-in-out;
          animation: particle-pwe-down-arrow-show 0.3s ease-in-out;
}
@-webkit-keyframes particle-pwe-down-arrow-show {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes particle-pwe-down-arrow-show {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-down-arrow.particle-pwe-down-arrow-hide {
  display: none;
}
.particle-wallet-entry-container .particle-pwe-iframe-content {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: -1px 3px 11px 2px #00000073;
          box-shadow: -1px 3px 11px 2px #00000073;
  display: none;
  height: 650px;
  overflow: hidden;
  position: fixed;
  width: 400px;
  z-index: 1000;
}
.particle-wallet-entry-container .particle-pwe-iframe-content.particle-pwe-full-screen-iframe-content {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}
@media screen and (max-height: 660px) {
  .particle-wallet-entry-container .particle-pwe-iframe-content {
    height: 600px;
    width: 360px;
  }
}
.particle-wallet-entry-container .particle-pwe-iframe-content.particle-pwe-iframe-content-show {
  display: block;
}
.particle-pwe-iframe-content-dark{
  background-color: #000 !important;
}
.particle-pwe-iframe-content-light{
  background-color: #fff !important;
}
.particle-wallet-entry-container .particle-pwe-iframe-content .particle-pwe-iframe {
  border: none;
  height: 100%;
  width: 100%;
}

`;

const renderStyle = () => {
    const className = 'particle-wallet-entry-style';
    const el = document.querySelector('.' + className);
    el && el.remove();
    const styleEl = document.createElement('style');
    styleEl.classList.add(className);
    styleEl.innerHTML = style;
    document.head.appendChild(styleEl);
};

export default renderStyle;
