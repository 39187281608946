export default {
    language: '简体中文',
    ConnectButton: {
        connectWallet: '连接钱包',
        wrongNetwork: '网络错误',
    },
    ConnectModal: {
        email: '邮箱',
        phone: '电话',
        google: 'Google',
        apple: 'Apple',
        facebook: 'Facebook',
        microsoft: 'Microsoft',
        linkedin: 'Linkedin',
        github: 'Github',
        discord: 'Discord',
        twitter: 'Twitter',
        twitch: 'Twitch',
        title1: '什么是钱包',
        title2: '连接钱包',
        tip1: '保护你全部Token和NFT的安全',
        tip2: '您可以管理数字资产，例如发送、接收和显示。',
        tip3: '一种新的登录方式',
        tip4: '您可以通过社交账户登录，加密钱包将自动生成，简单但很安全。',
        less: '收起',
        more: '更多',
    },
    ConnectForm: {
        loading1: '确认连接',
        loading2: '扫描二维码',
        copied_to_clipboard: '复制到粘贴板',
        copy_success: '复制成功',
    },
    ChainModal: {
        title: '切换网络',
        wrong: '检测到网络错误，请切换或断开后继续操作。',
        connected: '已连接',
        confirm: '在钱包中确认',
        disconnect: '断开连接',
        loading: '加载中',
        switchTip: '您的钱包不支持从此应用切换网络。请在钱包中尝试切换网络。',
    },
    AccountModal: {
        copied: '已复制',
        logout: '退出登录',
        wallet: '钱包',
        buy: '购买数字货币',
        copy_address: '复制地址',
    },
};
