import React, { useState, useEffect } from 'react';
import '../../styles/index.css';
import './index.css';
import QRCode from './qr';
import type { GroupItem } from '../ConnectModal/type';
import { useTranslation } from '../../provider/I18nContext';
import loading_icon from '../../assets/images/loading.png';

function ConnectForm({
    authItem,
    mobileUri,
}: {
    authItem: GroupItem | undefined;
    onClose?: () => void;
    mobileUri: string;
}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isCopy, setIsCopy] = useState(false);
    const [copyString, setCopyString] = useState(t('ConnectForm.copied_to_clipboard'));
    const [qrcode, setQrcode] = useState({
        value: '',
        logo: '',
    });

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            setQrcode({
                value: '',
                logo: '',
            });
            if (authItem && authItem.key) {
                if (authItem?.methods === 'qrcode' && mobileUri) {
                    if (mobileUri) {
                        setQrcode({
                            value: mobileUri,
                            logo: authItem.icon,
                        });
                        setLoading(false);
                    }
                }
            }
        };
        init();
    }, [authItem, mobileUri]);

    const copyText = (text) => {
        try {
            if (isCopy) return;
            setIsCopy(true);
            setCopyString(t('ConnectForm.copy_success') + '!');
            navigator.clipboard.writeText(text);
            setTimeout(() => {
                setCopyString(t('ConnectForm.copied_to_clipboard'));
                setIsCopy(false);
            }, 1000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="particle-connect-form-contaier center-center flex-column">
                {loading ? (
                    <>
                        <div className="center-center flex-column particle-loading">
                            <img src={authItem?.icon} alt="" />
                            <h3>
                                {' '}
                                {authItem?.methods === 'qrcode' && authItem?.name !== 'MetaMask'
                                    ? 'Connect with'
                                    : 'Opening'}{' '}
                                {authItem?.name}...
                            </h3>
                            <p>{t('ConnectForm.loading1')}</p>
                            <p>
                                <img
                                    className="particle-loading-img"
                                    width={30}
                                    height={30}
                                    src={loading_icon}
                                    alt=""
                                />
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="particle-qrcode center-center">
                            {qrcode.value && (
                                <QRCode
                                    value={qrcode.value}
                                    logo={qrcode.logo}
                                    logoBackgroundColor="#fff"
                                    size={700}
                                    logoSize={120}
                                    ecl="H"
                                    logoMargin={20}
                                ></QRCode>
                            )}
                        </div>

                        {qrcode.value && (
                            <div className="particle-copy-uri" onClick={() => copyText(qrcode.value)}>
                                {copyString}
                            </div>
                        )}
                        <div className="particle-form-footer center-center flex-column">
                            <span>
                                {t('ConnectForm.loading2')} {authItem?.name}
                            </span>
                            {/* {authItem?.name === 'WalletConnect' || authItem?.name === 'Rainbow' ? (
                                <div className="tips center-between">
                                    Don’t have {authItem?.name}?
                                    <div className="open-qrcode" onClick={openConnect}>
                                        GET
                                    </div>
                                </div>
                            ) : undefined} */}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default ConnectForm;
