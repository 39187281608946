import type { Wallet } from '../../types';
import { isAndroid } from '../../utils';
import WalletIcon from '../logos/okxWallet.svg';
import { InjectedConnector } from './injected';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

export const okx = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'okx',
    name: 'OKX Wallet',
    iconUrl: WalletIcon,
    downloadUrls: {
        qrCode: 'https://okx.com/download',
        browserExtension: 'https://okx.com/download',
    },
    createConnector: (chains) => {
        const isOKXInjected =
            typeof window !== 'undefined' &&
            // @ts-expect-error
            typeof window.okxwallet !== 'undefined';

        const shouldUseWalletConnect = !isOKXInjected;

        const evmChains = chains?.filter((chain) => chain.name.toLowerCase() !== 'solana');

        const connector = shouldUseWalletConnect
            ? getWalletConnectConnector('okx', options, chains)
            : new InjectedConnector({ chains: evmChains, getProvider: () => (window as any).okxwallet });

        const getAppLinkUri = () => {
            return isAndroid() ? undefined : 'okex://main/wc';
        };

        return {
            connector,
            mobile: {
                getAppLinkUri: shouldUseWalletConnect ? getAppLinkUri : undefined,
            },
        };
    },
});
