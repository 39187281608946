import { useParticleConnect } from './useParticleConnect';

export async function useWalletMetas() {
    const { connectKit } = useParticleConnect();
    if (connectKit && connectKit.walletMetas) {
        const walletMetas = await connectKit.walletMetas();
        return walletMetas;
    }
    return Promise.resolve([]);
}
