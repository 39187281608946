import { chains as chainUtils } from '@particle-network/chains';
import type { Chain, ChainInfo, ParticleConnect, Provider } from '@particle-network/connect';
import { ParticleChains, isEVMProvider, isSolanaProvider } from '@particle-network/connect';
import type { ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
// import '../utils/i18n';

// This type is a combination of wagmi and ParticleKit chain types to make
// it easier for consumers to define their chain config in a single place.
export type ParticleKitChain = Chain;
interface ParticleKitChainContextValue {
    chains: Chain[];
    chain?: Chain;
    isEvm: boolean;
    isSolana: boolean;
    renderChains: any[];
    updateIsEvm?: (bool: boolean) => void;
    updateIsSolana?: (bool: boolean) => void;
    isSwtichChain: boolean;
}

const ParticleKitChainContext = createContext<ParticleKitChainContextValue>({
    chains: [],
    isEvm: false,
    isSolana: false,
    renderChains: [],
    isSwtichChain: true,
});

interface ParticleKitChainProviderProps {
    chains: Chain[];
    chain?: Chain;
    initialChain?: Chain | undefined;
    children: ReactNode;
    userChains: Chain[];
    provider: Provider | undefined;
    connectKit?: ParticleConnect;
}

export function ParticleKitChainProvider({
    chains,
    children,
    chain,
    userChains,
    provider,
    connectKit,
}: ParticleKitChainProviderProps) {
    const [isEvm, setIsEvm] = useState(false);
    const [isSolana, setIsSolana] = useState(false);
    const [unsupportedChain, setUnsupportedChain] = useState(false);
    const updateIsEvm = useCallback((bool: boolean): void => setIsEvm(bool), []);
    const updateIsSolana = useCallback((bool: boolean): void => setIsSolana(bool), []);
    const connectId = connectKit?.cachedProviderId?.() || '';

    const renderChains = useMemo(() => {
        if (connectId && userChains && provider) {
            let rchains = [...userChains];
            const isEvm = provider && isEVMProvider(provider);
            const isSolana = provider && isSolanaProvider(provider);
            // 判断第三方钱包的链是否为evm 去处非solana的链
            if (isSolana && connectId !== 'particle') {
                rchains = rchains.filter((item) => [101, 102, 103].includes(item.id));
            }
            // 判断第三方钱包的链是否为evm 去处solana的链
            if (isEvm && connectId !== 'particle') {
                rchains = rchains.filter((item) => {
                    return ![101, 102, 103].includes(item.id) && item.name.toLowerCase() !== 'tron';
                });
            }

            const rest = rchains.map((chain) => {
                const chainInfo = chainUtils.getChainInfo(chain);
                if (chainInfo) {
                    return chainInfo;
                }
                return {
                    icon: '',
                    network: '',
                    ...chain,
                };
            });
            console.log('renderChains', rest);
            return rest;
        }
        return [];
    }, [connectId, userChains, provider]);

    const isSwtichChain = useMemo(() => {
        if (!connectKit) return false;
        if (!connectId) return false;
        if (connectId === 'particle') return true;
        const connector = connectKit?.getConnector(connectId)?.connector;
        // @ts-ignore
        if (connector && connectId && !!connector?.switchChain) {
            return true;
        } else {
            return false;
        }
    }, [connectId, connectKit]);

    return (
        <ParticleKitChainContext.Provider
            value={useMemo(
                () => ({
                    chains,
                    chain,
                    isEvm,
                    isSolana,
                    updateIsSolana,
                    updateIsEvm,
                    unsupportedChain,
                    setUnsupportedChain,
                    renderChains,
                    isSwtichChain,
                }),
                [
                    chains,
                    chain,
                    isEvm,
                    isSolana,
                    updateIsSolana,
                    updateIsEvm,
                    unsupportedChain,
                    setUnsupportedChain,
                    renderChains,
                    isSwtichChain,
                ]
            )}
        >
            {children}
        </ParticleKitChainContext.Provider>
    );
}

export const useParticleKitChains = () => useContext(ParticleKitChainContext).chains;
export const useInitialChainId = () => useContext(ParticleKitChainContext)?.chain?.id;

export const useIsEvm = () => {
    const { updateIsEvm, isEvm } = useContext(ParticleKitChainContext);
    return { updateIsEvm, isEvm };
};

export const useIsSolana = () => {
    const { updateIsSolana, isSolana } = useContext(ParticleKitChainContext);
    return { updateIsSolana, isSolana };
};

export const useSwitchChains = () => {
    const { isSwtichChain, renderChains } = useContext(ParticleKitChainContext);
    return { isSwtichChain, renderChains };
};

export const useCurrentChain = (): ChainInfo | undefined => {
    const initialChain = useContext(ParticleKitChainContext)?.chain;
    if (!initialChain) return undefined;
    const findKey = Object.keys(ParticleChains).find((key) => ParticleChains[key].id === initialChain.id);
    if (findKey) return ParticleChains[findKey];
    return undefined;
};
