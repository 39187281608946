import type { Chain } from '@particle-network/chains';
import type { DownloadUrls, EVMProvider, Wallet } from '../../types/types';
import { EVMConnector } from './base';
export class InjectedConnector extends EVMConnector {
    #getProvider;
    constructor({ chains, getProvider }: { chains?: Chain[]; getProvider?: () => any }) {
        super(chains);
        this.#getProvider = getProvider;
    }
    async connect(): Promise<EVMProvider> {
        const provider = await this.getProvider();
        if (provider) {
            if (provider.on && provider.off) {
                provider.off('accountsChanged', this.onAccountsChanged);
                provider.on('accountsChanged', this.onAccountsChanged);
                provider.off('chainChanged', this.onChainChanged);
                provider.on('chainChanged', this.onChainChanged);
                provider.off('disconnect', this.onDisconnect);
                provider.on('disconnect', this.onDisconnect);
            }
            this.emit('message', { type: 'connecting' });
            await provider.request({ method: 'eth_requestAccounts' });

            if (!provider.isMetaMask && !provider.isParticle && !provider.isTrust) {
                this.switchChain = undefined;
            }
        } else {
            throw new Error('No Web3 Provider found');
        }
        this.provider = provider;
        return provider;
    }

    async disconnect(): Promise<void> {
        this.provider?.off?.('accountsChanged', this.onAccountsChanged);
        this.provider?.off?.('chainChanged', this.onChainChanged);
        this.provider?.off?.('disconnect', this.onDisconnect);
        this.provider = undefined;
    }

    async getProvider() {
        return this.#getProvider ? this.#getProvider() : window.ethereum;
    }

    private onAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            this.provider = undefined;
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        this.provider = undefined;
        this.emit('disconnect');
    };
}

export interface InjectedOptions {
    id: string;
    name: string;
    iconUrl: string;
    downloadUrls?: DownloadUrls;
}

export const injectedEVM = (option?: InjectedOptions): Wallet => ({
    id: option?.id ?? 'injected',
    name: option?.name ?? 'Injected Wallet',
    iconUrl: option?.iconUrl ?? '',
    downloadUrls: option?.downloadUrls,
    createConnector: (chains?: Chain[]) => {
        return {
            connector: new InjectedConnector({
                chains: chains?.filter((chain) => chain.name.toLowerCase() !== 'solana'),
            }),
        };
    },
});
