import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import type { Chain, ConnectConfig, Provider } from '@particle-network/connect';
import { ParticleConnect, getPublicAddress } from '@particle-network/connect';
import ConnectModal from '../components/ConnectModal';
import ChainModal from '../components/ChainModal';
import { useIsDarkMode } from '../hooks/useIsDarkMode';
import { ParticleKitChainProvider } from './ModalChainContext';
import { I18nProvider, useTranslation } from './I18nContext';
import { getVersion } from '../utils/common';

interface ModalContextValue {
    userChains: Chain[];
    account?: string;
    chain?: Chain;
    updateChain?: (chain?: Chain) => void;
    accountModalOpen: boolean;
    chainModalOpen: boolean;
    connectModalOpen: boolean;
    particleProvider?: Provider;
    connectKit?: ParticleConnect;
    accountLoading?: boolean;
    connectId?: string;
    walletSort: string[];
    particleAuthSort: string[];
    openAccountModal?: () => void;
    closeAccountModal?: () => void;
    openChainModal?: () => void;
    openConnectModal?: () => void;
    updateParticleProvider?: (obj?: Provider) => void;
    updateAccount?: (obj: string | undefined) => void;
    updateConnectId?: (obj: string) => void;
    setAccountLoading?: (obj: boolean) => void;
    unsupportedChain: boolean;
    setUnsupportedChain?: (bool: boolean) => void;
    setTheme?: (theme: string) => void;
    theme: string;
    language: string;
    changLanguage: (val: string) => any;
    cacheProvider: boolean;
}

export const ModalContext = createContext<ModalContextValue>({
    userChains: [],
    theme: 'auto',
    accountModalOpen: false,
    connectModalOpen: false,
    chainModalOpen: false,
    walletSort: ['Particle Auth', 'Wallet'],
    particleAuthSort: ['email', 'phone', 'google', 'apple', 'facebook', 'microsoft', 'linkedin', 'github', 'discord'],
    unsupportedChain: false,
    language: 'en',
    changLanguage: (val: string) => {},
    cacheProvider: true,
});

interface ModalProviderProps {
    children: ReactNode;
    options: ConnectConfig;
    theme?: 'auto' | 'dark' | 'light';
    walletSort?: string[];
    particleAuthSort?: string[];
    language?: string;
    cacheProvider?: boolean;
}

function useModalStateValue() {
    const [isModalOpen, setModalOpen] = useState(false);
    return {
        closeModal: useCallback(() => setModalOpen(false), []),
        isModalOpen,
        openModal: useCallback(() => setModalOpen(true), []),
    };
}

export function ModalProvider({
    children,
    options,
    theme: optionsTheme,
    walletSort = ['Particle Auth', 'Wallet'],
    particleAuthSort = [
        'email',
        'phone',
        'google',
        'apple',
        'facebook',
        'twitter',
        'microsoft',
        'linkedin',
        'github',
        'discord',
    ],
    language: lang,
    cacheProvider = true,
}: ModalProviderProps) {
    const userChains = useMemo(() => options.chains, [options]);
    const [theme, setTheme] = useState(optionsTheme || 'auto');
    const [unsupportedChain, setUnsupportedChain] = useState(false);
    const [account, setAccount] = useState(undefined);
    const [chain, setChain] = useState<Chain | undefined>(undefined);
    const [particleProvider, setParticleProvider] = useState<Provider | undefined>(undefined);
    const [connectId, setConnectId] = useState<string>('');
    const [classTheme, setClassTheme] = useState<string>('');
    const autoTheme = useIsDarkMode();
    const [accountLoading, setAccountLoading] = useState<boolean>(true);
    const [language, setLanguage] = useState<string>(lang ?? 'en');
    const { i18n } = useTranslation();
    const connectKit = useMemo<ParticleConnect>(() => {
        if (typeof window !== 'undefined') {
            //@ts-ignore
            if (!window.particleConnect) {
                const kit = new ParticleConnect(options);
                kit.particle.setLanguage(language);
                //@ts-ignore
                kit.reactUIVersion = getVersion();
                //@ts-ignore
                window.particleConnect = kit;
            }
            //@ts-ignore
            return window.particleConnect;
        }
        return {};
    }, [options]);

    useEffect(() => {
        if (i18n) i18n.changeLanguage(language);
        connectKit?.particle.setLanguage(language);
        console.log('particle change language:', language);
    }, [language]);

    useEffect(() => {
        switch (theme) {
            case 'auto':
                setClassTheme(autoTheme ? 'particle-connect-ui-theme-dark' : 'particle-connect-ui-theme-ligth');
                break;
            case 'light':
                setClassTheme('particle-connect-ui-theme-ligth');
                break;
            case 'dark':
                setClassTheme('particle-connect-ui-theme-dark');
                break;
            default:
                setClassTheme(autoTheme ? 'particle-connect-ui-theme-dark' : 'particle-connect-ui-theme-ligth');
                break;
        }
        if (connectKit?.particle?.setAuthTheme && theme) {
            connectKit.particle.setAuthTheme({
                uiMode: theme || 'auto',
            });
        }
    }, [theme, autoTheme]);
    const {
        closeModal: closeConnectModal,
        isModalOpen: connectModalOpen,
        openModal: openConnectModal,
    } = useModalStateValue();

    const {
        closeModal: closeAccountModal,
        isModalOpen: accountModalOpen,
        openModal: openAccountModal,
    } = useModalStateValue();

    const {
        closeModal: closeChainModal,
        isModalOpen: chainModalOpen,
        openModal: openChainModal,
    } = useModalStateValue();

    const updateParticleProvider = useCallback((provider): void => setParticleProvider(provider), []);
    const updateAccount = useCallback((account): void => setAccount(account), []);
    const updateConnectId = useCallback((id): void => setConnectId(id), []);
    const updateChain = useCallback((chain?: Chain): void => setChain(chain), []);
    const updateTheme = useCallback((theme): void => setTheme(theme), []);
    const updateLanguage = useCallback((val): void => setLanguage(val.replace('_', '-')), []);

    useEffect(() => {
        // Listen for events after logging in
        if (!!connectKit?.connect && particleProvider) {
            const onDisconnect = () => {
                updateParticleProvider(undefined);
                updateAccount('');
                updateConnectId('');
                closeAccountModal();
                closeChainModal();
                closeConnectModal();
            };
            const onAccountsChanged = (accounts) => {
                if (accounts && accounts.length > 0) {
                    updateAccount?.(accounts[0]);
                }
            };
            const onChainChanged = async (chain) => {
                setChain(chain);
                setUnsupportedChain(!chain || !userChains.find((x) => x.id === chain.id));
                const account = await getPublicAddress(particleProvider);
                updateAccount(account);
                console.log('chainChanged  update accounts:' + account);
                console.log('onChainChanged：', chain);
                closeChainModal();
            };
            const onProviderChanged = async (provider) => {
                setParticleProvider(provider);
                const account = await getPublicAddress(provider);
                console.log('providerChanged  update accounts:' + account);
                updateAccount(account);
            };
            connectKit.on('disconnect', onDisconnect);
            connectKit.on('accountsChanged', onAccountsChanged);
            connectKit.on('chainChanged', onChainChanged);
            connectKit.on('providerChanged', onProviderChanged);
            return () => {
                connectKit.removeListener('disconnect', onDisconnect);
                connectKit.removeListener('accountsChanged', onAccountsChanged);
                connectKit.removeListener('chainChanged', onChainChanged);
                connectKit.removeListener('providerChanged', onProviderChanged);
            };
        }

        return () => {};
    }, [connectKit, particleProvider]);

    return (
        <I18nProvider>
            <ParticleKitChainProvider
                provider={particleProvider}
                chains={options.chains}
                chain={chain}
                userChains={userChains}
                connectKit={connectKit}
            >
                <ModalContext.Provider
                    value={useMemo(
                        () => ({
                            userChains,
                            unsupportedChain,
                            setUnsupportedChain,
                            walletSort,
                            particleAuthSort,
                            accountLoading,
                            connectKit,
                            account,
                            connectId,
                            particleProvider,
                            accountModalOpen,
                            chainModalOpen,
                            connectModalOpen,
                            setAccountLoading,
                            updateParticleProvider,
                            updateConnectId,
                            updateAccount,
                            openAccountModal,
                            openConnectModal,
                            openChainModal,
                            chain,
                            updateChain,
                            setTheme: updateTheme,
                            closeAccountModal,
                            theme,
                            language,
                            changLanguage: updateLanguage,
                            cacheProvider,
                        }),
                        [
                            cacheProvider,
                            unsupportedChain,
                            setUnsupportedChain,
                            closeAccountModal,
                            walletSort,
                            particleAuthSort,
                            accountLoading,
                            account,
                            connectKit,
                            particleProvider,
                            accountModalOpen,
                            connectModalOpen,
                            connectId,
                            chainModalOpen,
                            setAccountLoading,
                            updateParticleProvider,
                            openAccountModal,
                            openConnectModal,
                            updateAccount,
                            updateConnectId,
                            openChainModal,
                            openChainModal,
                            chain,
                            updateChain,
                            userChains,
                            updateTheme,
                            theme,
                            language,
                            updateLanguage,
                        ]
                    )}
                >
                    <div className={'particle-connect-ui ' + classTheme}>
                        {children}
                        <div className="connect-body">
                            <ConnectModal onClose={closeConnectModal} open={connectModalOpen} />
                            {/* <AccountModal onClose={closeAccountModal} open={accountModalOpen} /> */}
                            <ChainModal
                                chains={options.chains}
                                onClose={closeChainModal}
                                open={chainModalOpen}
                            ></ChainModal>
                        </div>
                    </div>
                </ModalContext.Provider>
            </ParticleKitChainProvider>
        </I18nProvider>
    );
}

// 获取theme
export function useParticleTheme() {
    const { theme, setTheme } = useContext(ModalContext);
    return {
        theme,
        setTheme,
    };
}

// 获取language
export function useLanguage() {
    const { language, changLanguage } = useContext(ModalContext);
    return {
        language,
        changLanguage,
    };
}
// 获取connectKi
export function useConnectKit(): ParticleConnect {
    const { connectKit } = useContext(ModalContext);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return connectKit!;
}

export const useChain = () => {
    const { updateChain } = useContext(ModalContext);
    return { updateChain };
};

export const useUnsupportedChain = () => {
    const { setUnsupportedChain, unsupportedChain } = useContext(ModalContext);
    return { setUnsupportedChain, unsupportedChain };
};

// 获取操作行为
export function useConnectAction() {
    const { updateParticleProvider, updateAccount, updateConnectId } = useContext(ModalContext);
    return {
        updateParticleProvider,
        updateAccount,
        updateConnectId,
    };
}

// 获取cacheProvider 判断是否自动调用connectToCachedProvider方法
export function useIsCacheProvider() {
    const { cacheProvider } = useContext(ModalContext);
    return cacheProvider;
}

// 获取所有弹窗状态
export function useModalState() {
    const { accountModalOpen, connectModalOpen, chainModalOpen } = useContext(ModalContext);
    return {
        accountModalOpen,
        chainModalOpen,
        connectModalOpen,
    };
}

// 获取userChains
export function useUserChains() {
    const { userChains } = useContext(ModalContext);
    return { userChains };
}

// 获取account弹窗
export function useAccountModal() {
    const { openAccountModal, closeAccountModal } = useContext(ModalContext);
    return { openAccountModal, closeAccountModal };
}

// 获取connect弹窗
export function useConnectModal() {
    const { openConnectModal } = useContext(ModalContext);
    return { openConnectModal };
}

// 获取connect弹窗
export function useChainModal() {
    const { openChainModal } = useContext(ModalContext);
    return { openChainModal };
}

export function useAccountInfo() {
    const { account, particleProvider, connectId, accountLoading, setAccountLoading } = useContext(ModalContext);
    return {
        account,
        connectId,
        particleProvider,
        accountLoading,
        setAccountLoading,
    };
}

export function useWalletSort() {
    const { walletSort } = useContext(ModalContext);
    return { walletSort };
}

export function useParticleAuthWalletSort() {
    const { particleAuthSort } = useContext(ModalContext);
    return { particleAuthSort };
}
