export default {
    language: 'English',
    ConnectButton: {
        connectWallet: 'Connect Wallet',
        wrongNetwork: 'Wrong network',
    },
    ConnectModal: {
        email: 'Email',
        phone: 'Phone',
        google: 'Google',
        apple: 'Apple',
        facebook: 'Facebook',
        microsoft: 'Microsoft',
        linkedin: 'Linkedin',
        github: 'Github',
        discord: 'Discord',
        twitter: 'Twitter',
        twitch: 'Twitch',
        title1: 'What is a Wallet',
        title2: 'Connect a Wallet',
        tip1: 'A Home for your Tokens and NFTs',
        tip2: 'You can manage your digital assets, for example send, receive and display.',
        tip3: 'A New Way to Log In',
        tip4: ' You can log in through your social account, and a crypto wallet will be automatically generated, simple but secure.',
        less: 'Less',
        more: 'More',
    },
    ConnectForm: {
        loading1: 'Confirm connection in the Window',
        loading2: 'Scan QR code with',
        copied_to_clipboard: 'Copy to clipboard',
        copy_success: 'Copy success',
    },
    ChainModal: {
        title: 'Switch Networks',
        wrong: 'Wrong network detected, switch or disconnect to continue.',
        connected: 'Connected',
        confirm: 'Confirm in Wallet',
        disconnect: 'Disconnect',
        loading: 'loading',
        switchTip:
            ' Your wallet does not support switching networks from this app. Try switching networks from within your wallet instead.',
    },
    AccountModal: {
        copied: 'copied',
        logout: 'Logout',
        wallet: 'Wallet',
        buy: 'Buy crypto',
        copy_address: 'Copy address',
    },
};
