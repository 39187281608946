import React, { useState, useEffect, memo, useCallback } from 'react';
import { useAccount } from '../../hooks/useAccount';
import '../../styles/index.css';
import './index.css';
import Avatar from '../../services/AuthAvatar';
import { useParticleConnect } from '../../hooks/useParticleConnect';
import { useTranslation } from '../../provider/I18nContext';
import { useConnectId, useConnectKit, useCurrentChain } from '../../hooks';
import { toBase58Address } from '@particle-network/auth';
import { shortString } from '../../utils';
import { ReactSVG } from 'react-svg';
import WalletSvg from '../../assets/images/wallet_svg.svg';
import CopySvg from '../../assets/images/copy_svg.svg';
import BuySvg from '../../assets/images/buy_svg.svg';
import LogoutSvg from '../../assets/images/logout_svg.svg';
import CloseSvg from '../../assets/images/close_svg.svg';

export interface AccountModalProps {
    open: boolean;
    mode?: string;
    onClose?: () => void;
}

export function AccountModal({ open, onClose, mode }: AccountModalProps) {
    const account = useAccount();
    const { t } = useTranslation();
    const modalCloseHandle = () => {
        onClose && onClose();
    };
    const [accountName, setAccountName] = useState(account);
    const [copiedAddress, setCopiedAddress] = useState(false);
    const connectId = useConnectId();
    const connectKit = useConnectKit();
    console.log(connectId);
    const chain = useCurrentChain();
    const { disconnect } = useParticleConnect();
    const [overflow, setOverflow] = useState<string[]>([]);
    useEffect(() => {
        if (open) {
            const htmlOverFlow = document.getElementsByTagName('html')[0].style.overflow;
            const bodyOverFlow = document.getElementsByTagName('body')[0].style.overflow;
            setOverflow([htmlOverFlow, bodyOverFlow]);
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('html')[0].style.overflow = overflow[0];
            document.getElementsByTagName('body')[0].style.overflow = overflow[1];
            setOverflow([]);
        }
    }, [open]);

    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;
    };

    useEffect(() => {
        if (account && chain?.name?.toLowerCase() === 'tron') {
            setAccountName(shortString(toBase58Address(account)));
        } else {
            setAccountName(shortString(account));
        }
    }, [account, chain]);

    const copyAddressAction = useCallback(() => {
        if (account) {
            navigator.clipboard.writeText(account);
            setCopiedAddress(true);
            setTimeout(() => {
                setCopiedAddress(false);
            }, 1000);
        }
    }, [account]);

    const logout = useCallback(async () => {
        await disconnect({ hideLoading: true });
        onClose && onClose();
    }, []);

    const toWallet = () => {
        if (connectKit) {
            connectKit?.particle?.openWallet?.();
        }
    };

    const toBuy = () => {
        if (connectKit) {
            connectKit?.particle?.openBuy?.();
        }
    };
    return (
        <>
            {open ? (
                <>
                    {/* masker */}
                    <div
                        className={'particle-account-modal-container center-center show '}
                        onClick={modalCloseHandle}
                        onTouchStart={stopPropagation}
                        onScroll={stopPropagation}
                    ></div>

                    <div
                        className={`particle-account-modal-box  center-start flex-column  show ${
                            mode || 'bottomRight'
                        }`}
                        onScroll={stopPropagation}
                    >
                        {/* <div className="particle-account-modal-close hover" onClick={modalCloseHandle}></div> */}
                        <div className="particle-account-header">
                            <div className="particle-avatar">
                                <Avatar opts={{ seed: account, size: 33, scale: 1 }} />
                            </div>
                            <span className="particle-account-address">{accountName}</span>
                            <ReactSVG
                                src={CloseSvg}
                                className="particle-account-modal-close hover"
                                onClick={modalCloseHandle}
                            ></ReactSVG>
                        </div>
                        {connectId === 'particle' && (
                            <>
                                <div className="particle-link-item" onClick={toWallet}>
                                    {/* @ts-ignore */}
                                    <ReactSVG src={WalletSvg} className="icon"></ReactSVG>
                                    {t('AccountModal.wallet')}
                                </div>

                                <div className="particle-link-item" onClick={toBuy}>
                                    <ReactSVG src={BuySvg} className="icon"></ReactSVG>
                                    {t('AccountModal.buy')}
                                </div>
                            </>
                        )}

                        <div className="particle-link-item" onClick={copyAddressAction}>
                            <ReactSVG src={CopySvg} className="icon"></ReactSVG>
                            {copiedAddress ? <>{t('AccountModal.copied')}!</> : t('AccountModal.copy_address')}
                        </div>

                        <div className="particle-link-item red" onClick={logout}>
                            <ReactSVG src={LogoutSvg} className="icon"></ReactSVG>
                            {t('AccountModal.logout')}
                        </div>
                    </div>
                </>
            ) : undefined}
        </>
    );
}

export default memo(AccountModal);
