import type { Wallet } from '../../types';
import { isAndroid } from '../../utils';
import ONTOLogo from '../logos/onto.svg';
import { InjectedConnector } from './injected';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

export const onto = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'onto',
    name: 'ONTO Wallet',
    iconUrl: ONTOLogo,
    downloadUrls: {
        qrCode: 'https://onto.app/download/',
        browserExtension: 'https://onto.app/download/',
    },
    createConnector: (chains) => {
        const isWalletInjected = typeof window !== 'undefined' && typeof window.onto !== 'undefined';

        const shouldUseWalletConnect = !isWalletInjected;

        const evmChains = chains?.filter((chain) => chain.name.toLowerCase() !== 'solana');

        const connector = shouldUseWalletConnect
            ? getWalletConnectConnector('onto', options, chains)
            : new InjectedConnector({ chains: evmChains, getProvider: () => window.onto });

        const getAppLinkUri = () => {
            return isAndroid() ? undefined : 'ontoprovider://wc';
        };

        return {
            connector,
            mobile: {
                getAppLinkUri: shouldUseWalletConnect ? getAppLinkUri : undefined,
            },
        };
    },
});
