import type Draggabilly from 'draggabilly';
import { Auth } from '../../auth';
import { walletUrl } from '../../constant';
import { Config } from '../../types';
import { isBraveBrowser, isNullish } from '../../utils';
import { getWalletUIMode, getWalletUrl } from '../../utils/wallet-url';
import htmlRender from './html';
import { downArrowDarkBase64, walletIconDarkBase64 } from './iconsBase64';
import styleRender from './style';

const fullScreenClass = 'particle-pwe-full-screen-iframe-content';

let isListen = false;

export enum WalletEntryPosition {
    BR = 'bottom-right',
    BL = 'bottom-left',
    TR = 'top-right',
    TL = 'top-left',
}

export interface WalletEntryOptions {
    position?: WalletEntryPosition;
    preload?: boolean;
}

let timer: any = null;
let draggie: Draggabilly;

export class WalletEntryPlugin {
    static WALLET_BTN_POSITION = 'walletBtnPosition';
    private auth: Auth;
    private walletEntryOptions: WalletEntryOptions;
    private config: Config;

    constructor(config: Config, auth: Auth, options: WalletEntryOptions) {
        this.config = config;
        this.auth = auth;
        this.walletEntryOptions = options;

        if (typeof window !== 'undefined') {
            this.destroy();

            if (this.auth.isLogin()) {
                this.walletEntryRander();
            }

            this.auth.on('connect', () => {
                this.walletEntryRander();
            });

            this.auth.on('disconnect', () => {
                this.destroy();
            });
        }
    }

    private isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    public openWallet(params?: { windowSize: string }) {
        if (!this.auth?.isLogin?.()) {
            console.log('not login');
            return;
        }

        const url = getWalletUrl(this.auth);

        if (this.isMobile() || isBraveBrowser()) {
            window.open(url);
            return false;
        }
        const walletIcon: any = document.querySelector('.particle-pwe-wallet-icon');
        const downArrow = document.querySelector('.particle-pwe-down-arrow');
        const iframeContent: any = document.querySelector('.particle-pwe-iframe-content');

        if (params?.windowSize === 'large') {
            iframeContent?.classList.add(fullScreenClass);
        }

        if (!isNullish(this.auth?.getAuthTheme()?.modalBorderRadius)) {
            iframeContent.style.borderRadius = `${this.auth?.getAuthTheme()?.modalBorderRadius}px`;
        }
        iframeContent?.classList.add('particle-pwe-iframe-content-show');

        const uiMode = getWalletUIMode(this.auth);
        if (uiMode == 'dark') {
            iframeContent?.classList.add('particle-pwe-iframe-content-dark');
            iframeContent?.classList.remove('particle-pwe-iframe-content-light');
        } else {
            iframeContent?.classList.add('particle-pwe-iframe-content-light');
            iframeContent?.classList.remove('particle-pwe-iframe-content-dark');
        }

        let iframe: HTMLIFrameElement | null = document.querySelector('.particle-pwe-iframe');

        if (iframe?.src && new URLSearchParams(iframe?.src).get('theme') !== uiMode) {
            iframe.remove();
            iframe = null;
        }

        if (!iframe) {
            iframe = document.createElement('iframe');
            iframe.className = 'particle-pwe-iframe';
            iframe.allow = 'camera';
            iframeContent?.appendChild(iframe);
            iframe.src = url;
        }

        walletIcon?.classList.add('particle-pwe-wallet-icon-hide');
        downArrow?.classList.remove('particle-pwe-down-arrow-hide');
        this.updateIframeContentPosition();
    }

    public setWalletIcon() {
        const walletIconEl: any = document.querySelector('.particle-pwe-wallet-icon');
        const downArrowEl: any = document.querySelector('.particle-pwe-down-arrow');

        walletIconEl && walletIconEl.setAttribute('src', walletIconDarkBase64);
        downArrowEl && downArrowEl.setAttribute('src', downArrowDarkBase64);
    }

    private updateIframeContentPosition() {
        const iframeContent: any = document.querySelector('.particle-pwe-iframe-content');
        if (!iframeContent || iframeContent?.style?.display === 'none') {
            return;
        }
        const walletBtn: any = document.querySelector('.particle-pwe-btn');
        if (!this.config.wallet?.displayWalletEntry) {
            walletBtn.style.display = 'none';
        }
        const walletBtnRect = walletBtn.getBoundingClientRect();
        const iframeContentRect = iframeContent.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const iframeContentHeight = iframeContentRect.height;
        const iframeContentWidth = iframeContentRect.width;
        const walletBtnHeight = walletBtnRect.height;
        // const walletBtnWidth = walletBtnRect.width;
        const walletBtnTop = walletBtnRect.top;
        const walletBtnLeft = walletBtnRect.left;
        const walletBtnBottom = walletBtnRect.bottom;
        const walletBtnRight = walletBtnRect.right;

        if (
            walletBtnBottom + iframeContentHeight + 10 < windowHeight &&
            walletBtnRight + iframeContentWidth + 10 < windowWidth
        ) {
            iframeContent.style.top = walletBtnBottom + 10 + 'px';
            iframeContent.style.left = walletBtnLeft + 'px';
        } else if (
            walletBtnBottom + iframeContentHeight + 10 < windowHeight &&
            walletBtnLeft - iframeContentWidth - 10 > 0
        ) {
            iframeContent.style.top = walletBtnBottom + 10 + 'px';
            iframeContent.style.left = walletBtnRight - iframeContentWidth + 'px';
        } else if (
            walletBtnTop - iframeContentHeight - 10 > 0 &&
            walletBtnRight + iframeContentWidth + 10 < windowWidth
        ) {
            iframeContent.style.top = walletBtnTop - iframeContentHeight - 10 + 'px';
            iframeContent.style.left = walletBtnLeft + 'px';
        } else if (walletBtnTop - iframeContentHeight - 10 > 0 && walletBtnLeft - iframeContentWidth - 10 > 0) {
            iframeContent.style.top = walletBtnTop - iframeContentHeight - 10 + 'px';
            iframeContent.style.left = walletBtnRight - iframeContentWidth + 'px';
        } else if (walletBtnRight + iframeContentWidth + 10 < windowWidth) {
            const top = walletBtnTop + walletBtnHeight / 2 - iframeContentHeight / 2;
            iframeContent.style.top =
                top < 30
                    ? 30 + 'px'
                    : top > windowHeight - iframeContentHeight - 30
                    ? windowHeight - iframeContentHeight - 30 + 'px'
                    : top + 'px';
            iframeContent.style.left = walletBtnRight + 10 + 'px';
        } else if (walletBtnLeft - iframeContentWidth - 10 > 0) {
            const top = walletBtnTop + walletBtnHeight / 2 - iframeContentHeight / 2;
            iframeContent.style.top =
                top < 30
                    ? 30 + 'px'
                    : top > windowHeight - iframeContentHeight - 30
                    ? windowHeight - iframeContentHeight - 30 + 'px'
                    : top + 'px';
            iframeContent.style.left = walletBtnLeft - iframeContentWidth - 10 + 'px';
        } else {
            console.log('no space');
        }
    }

    private setButtonStorageData(data: any) {
        const { innerWidth, innerHeight } = window;
        const position = this.walletEntryOptions.position || WalletEntryPosition.BR;
        localStorage.setItem(
            WalletEntryPlugin.WALLET_BTN_POSITION,
            `${data.x},${data.y},${data.direction},${position}`
        );
        localStorage.setItem(WalletEntryPlugin.WALLET_BTN_POSITION + '_window', `${innerWidth},${innerHeight}`);
    }

    private getButtonStorageData() {
        const { innerWidth, innerHeight } = window;
        const [x, y, direction = 'right', position] =
            localStorage?.getItem(WalletEntryPlugin.WALLET_BTN_POSITION)?.split?.(',') || [];
        let [width, height]: any =
            localStorage.getItem(WalletEntryPlugin.WALLET_BTN_POSITION + '_window')?.split?.(',') || [];
        width = Number(width || 0);
        height = Number(height || 0);
        if (width && height && (width != innerWidth || height != innerHeight)) {
            localStorage.removeItem(WalletEntryPlugin.WALLET_BTN_POSITION);
            localStorage.removeItem(WalletEntryPlugin.WALLET_BTN_POSITION + '_window');
            return {
                x: innerWidth,
                y: innerHeight,
                position: position || this.walletEntryOptions.position || WalletEntryPosition.BR,
                direction: 'right',
            };
        }
        return {
            x: Number(x) || 0,
            y: Number(y) || 0,
            position: position || this.walletEntryOptions.position || WalletEntryPosition.BR,
            direction,
        };
    }

    private updateWalletBtnPosition(x: number, y: number, type = '') {
        const { width } = document.body.getBoundingClientRect();
        const { direction } = this.getButtonStorageData();
        const isRight = x > width / 2 || (type == 'windowResize' && direction === 'right');
        let left = isRight ? width - 60 : 0;
        let top = y;
        let newDirection = 'left';

        if (left < 30) {
            left = 30;
            newDirection = 'left';
        } else if (left > width - 90) {
            left = width - 90;
            newDirection = 'right';
        }
        if (top < 30) {
            top = 30;
        } else if (top > window.innerHeight - 90) {
            top = window.innerHeight - 90;
        }

        return {
            left,
            top,
            direction: newDirection,
        };
    }

    public closeWallet() {
        const iframeContent: HTMLDivElement | null = document.querySelector('.particle-pwe-iframe-content');
        iframeContent?.classList.remove('particle-pwe-iframe-content-show');
        const walletIcon: any = document.querySelector('.particle-pwe-wallet-icon');
        const downArrow = document.querySelector('.particle-pwe-down-arrow');
        walletIcon?.classList.remove('particle-pwe-wallet-icon-hide');
        downArrow?.classList.add('particle-pwe-down-arrow-hide');
    }

    private resize() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        return () => {
            clearTimeout(timer);

            timer = setTimeout(() => {
                localStorage.removeItem(WalletEntryPlugin.WALLET_BTN_POSITION);

                const walletBtn: HTMLDivElement | null = document.querySelector('.particle-pwe-btn');

                const { x: btnX, y: btnY } = this.getButtonStorageData();

                const x = btnX || window.innerWidth;
                const y = btnY || window.innerHeight;

                const { left, top, direction } = that.updateWalletBtnPosition(x, y, 'windowResize');
                if (walletBtn) {
                    walletBtn.style.left = left + 'px';
                    walletBtn.style.top = top + 'px';
                }

                this.setButtonStorageData({
                    x,
                    y,
                    direction,
                });

                that.updateIframeContentPosition();

                (async () => {
                    let count = 0;
                    do {
                        count++;
                        await this.sleep(100);
                        this.updateIframeContentPosition();
                    } while (count < 15);
                })();
            }, 10);
        };
    }

    private async sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    private preload() {
        const url = walletUrl();
        const script = document.createElement('script');
        script.src = `${url}/preload.js?_=${Math.floor(Date.now() / 60000) * 60000}`;
        script.setAttribute('data-target', 'web-wallet');
        document.body.appendChild(script);
    }

    private async walletEntryRander() {
        styleRender();
        htmlRender();
        this.setWalletIcon();

        if (this.walletEntryOptions?.preload !== false) {
            this.preload();
        }

        const walletBtn: any = document.querySelector('.particle-pwe-btn');
        // const maxZIndex = Math.max.apply(
        //     null,
        //     Array.from(document.querySelectorAll('*')).map((item) => {
        //         return Number(window.getComputedStyle(item).zIndex) || 0;
        //     })
        // );

        // walletBtn.style.zIndex = maxZIndex + 1;

        const { x: btnX, y: btnY } = this.getButtonStorageData();

        if (btnX && btnY) {
            if ((Number(btnX) || 0) < window.innerWidth && (Number(btnY) || 0) < window.innerHeight) {
                const { left, top } = this.updateWalletBtnPosition(Number(btnX) || 0, Number(btnY) || 0);
                walletBtn.style.left = left + 'px';
                walletBtn.style.top = top + 'px';
                this.updateIframeContentPosition();
            } else {
                const { left, top } = this.updateWalletBtnPosition(window.innerWidth, window.innerHeight);
                walletBtn.style.left = left + 'px';
                walletBtn.style.top = top + 'px';
                this.updateIframeContentPosition();
            }
        } else {
            // default wallet btn position
            const position = this.walletEntryOptions.position || WalletEntryPosition.BL;
            let top, left;
            if (position === WalletEntryPosition.BR) {
                top = window.innerHeight;
                left = window.innerWidth;
            } else if (position === WalletEntryPosition.TR) {
                top = 0;
                left = window.innerWidth;
            } else if (position === WalletEntryPosition.TL) {
                top = 0;
                left = 0;
            } else {
                top = window.innerHeight;
                left = 0;
            }

            const positionData = this.updateWalletBtnPosition(left, top);
            walletBtn.style.left = positionData.left + 'px';
            walletBtn.style.top = positionData.top + 'px';
            this.updateIframeContentPosition();
        }

        const walletIcon: any = document.querySelector('.particle-pwe-wallet-icon');
        const downArrow = document.querySelector('.particle-pwe-down-arrow');
        let isDraggie = false;

        // walletBtn 按下拖动位置到指定位置 draggabilly
        const Draggabilly = (await import('draggabilly')).default;
        draggie = new Draggabilly(walletBtn);

        draggie.on('dragStart', () => {
            isDraggie = true;
            walletBtn.style.cursor = 'move';
        });

        draggie.on('dragMove', () => {
            this.updateIframeContentPosition();
        });

        draggie.on('dragEnd', (event: any, pointer: any) => {
            event.stopPropagation();
            walletBtn.style.cursor = 'pointer';
            const { clientX: x, clientY: y } = pointer;
            const { left, top, direction } = this.updateWalletBtnPosition(x, y - 35);
            walletBtn.style.left = left + 'px';
            walletBtn.style.top = top + 'px';
            this.updateIframeContentPosition();

            (async () => {
                let count = 0;
                do {
                    count++;
                    await this.sleep(20);
                    this.updateIframeContentPosition();
                } while (count < 70);
            })();

            this.setButtonStorageData({
                x: left,
                y: top,
                direction,
            });

            setTimeout(() => {
                isDraggie = false;
            });
        });

        walletBtn.addEventListener('touchend', () => {
            if (!isDraggie) {
                this.openWallet();
            }
        });

        walletIcon?.addEventListener('click', (event: any) => {
            event?.stopPropagation?.();
            if (!isDraggie && !this.isMobile()) {
                this.openWallet();
            }
        });

        downArrow?.addEventListener('click', (e) => {
            e.stopPropagation();
            if (!isDraggie) {
                this.closeWallet();
            }
        });

        // @ts-ignore
        window.walletEntryPlugin = this;

        if (!isListen) {
            isListen = true;
            // @ts-ignore
            window.addEventListener('resize', window.walletEntryPlugin.resize(), false);
            window.addEventListener(
                'message',
                (events: any) => {
                    if (events?.data?.type === 'PARTICLE_WALLET_RESIZE_IFRAME') {
                        const iframeContent = document.querySelector('.particle-pwe-iframe-content') as HTMLDivElement;
                        const classList = iframeContent?.classList;

                        if (classList?.contains?.(fullScreenClass)) {
                            // @ts-ignore
                            if (!window.walletEntryPlugin.config.wallet?.displayWalletEntry) {
                                // @ts-ignore
                                window.walletEntryPlugin.closeWallet();
                            } else {
                                classList?.remove?.(fullScreenClass);
                                // @ts-ignore
                                window.walletEntryPlugin.resize();
                                // @ts-ignore
                                window.walletEntryPlugin.updateIframeContentPosition();
                            }
                        } else {
                            classList?.add?.(fullScreenClass);
                        }
                    }
                },
                false
            );
        }
    }

    public destroy() {
        draggie?.destroy?.();
        window.removeEventListener('resize', this.resize(), false);
        document.querySelector('.particle-wallet-entry-container')?.remove?.();
    }
}
