import { ParticleChains } from '.';
import type { Chain, ChainId, ChainInfo, ChainType } from './types';

export function getChainInfo(chain: Chain): ChainInfo | undefined {
    return ParticleChains[`${chain.name.toLowerCase()}-${chain.id}`];
}

export function getChainNetwork(chain: Chain): string {
    const target = getChainInfo(chain);
    return target?.network || 'UNKNOWN';
}

export function getChainSymbol(chain: Chain): string {
    const target = getChainInfo(chain);
    return target?.nativeCurrency?.symbol || 'UNKNOWN';
}

export function getChainType(chain: Chain): ChainType | undefined {
    const target = getChainInfo(chain);
    return target?.chainType;
}

export function isChainSupportEIP1559(chain: Chain): boolean {
    const target = getChainInfo(chain);
    return target?.features?.some((it) => it.name === 'EIP1559') ?? false;
}

export function getChainIcon(chain: Chain): string | undefined {
    const target = getChainInfo(chain);
    return target?.icon;
}

export function getEVMChainInfoById(id: ChainId): ChainInfo | undefined {
    return Object.values(ParticleChains).find((it) => it.chainType === 'evm' && it.id === id);
}

export function getSolanaChainInfoById(id: ChainId): ChainInfo | undefined {
    return Object.values(ParticleChains).find((it) => it.chainType === 'solana' && it.id === id);
}

export function getAllChainInfos(compareFn?: (a: ChainInfo, b: ChainInfo) => number): ChainInfo[] {
    const chains = Object.values(ParticleChains);
    if (compareFn) {
        return chains.sort(compareFn);
    }
    const sortKeys = [
        'Solana',
        'Ethereum',
        'BSC',
        'opBNB',
        'Polygon',
        'Avalanche',
        'Moonbeam',
        'Moonriver',
        'Heco',
        'Fantom',
        'Arbitrum',
        'Harmony',
        'Aurora',
        'Optimism',
        'KCC',
        'PlatON',
        'Tron',
    ];
    chains.sort((a, b) => {
        if (sortKeys.includes(a.name) && sortKeys.includes(b.name)) {
            if (a.name === b.name) {
                if (a.network === 'Mainnet') {
                    return -1;
                } else if (b.network === 'Mainnet') {
                    return 1;
                }
                return 0;
            } else if (sortKeys.indexOf(a.name) > sortKeys.indexOf(b.name)) {
                return 1;
            }
            return -1;
        } else if (sortKeys.includes(a.name)) {
            return -1;
        } else if (sortKeys.includes(b.name)) {
            return 1;
        } else if (a.name === b.name) {
            if (a.network === 'Mainnet') {
                return -1;
            } else if (b.network === 'Mainnet') {
                return 1;
            }
            return a.fullname.localeCompare(b.fullname);
        } else {
            return a.name.localeCompare(b.name);
        }
    });
    return chains;
}

export function getParticleNode(id: ChainId, projectId: String, projectKey: String): string {
    return `https://rpc.particle.network/evm-chain?chainId=${id}&projectUuid=${projectId}&projectKey=${projectKey}`;
}
