import { ERC4337Options } from './types';

let erc4337Option: boolean | ERC4337Options = false;

export const controller = {
    languageCode: 'en',
    fiatCoin: 'USD',
    get erc4337(): boolean | ERC4337Options {
        return erc4337Option;
    },

    set erc4337(option: boolean | ERC4337Options) {
        erc4337Option = option;
    },
};
