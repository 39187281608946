import { useMemo } from 'react';
import { useConnectKit } from '../provider/ModalContext';
import { useCurrentChain, useIsEvm, useIsSolana, useParticleKitChains } from '../provider/ModalChainContext';
export function useNetwork() {
    const chains = useParticleKitChains();
    const chain = useCurrentChain();
    const { isEvm, updateIsEvm } = useIsEvm();
    const { isSolana, updateIsSolana } = useIsSolana();
    const connectKit = useConnectKit();
    // const { connectKit } = useParticleConnect();
    return useMemo(
        () => ({
            chain,
            chains,
            isEvm,
            isSolana,
            network: isEvm ? 'evm' : isSolana ? 'solana' : undefined,
            connectId: connectKit?.cachedProviderId?.() || '',
            changeNetWork: (chainType) => {
                console.log('chainType:', chainType);
                switch (chainType) {
                    case 'solana':
                        updateIsEvm?.(false);
                        updateIsSolana?.(true);
                        break;
                    case 'evm':
                        updateIsEvm?.(true);
                        updateIsSolana?.(false);
                        break;
                }
            },
        }),
        [chain, chains, isEvm, isSolana, updateIsEvm, updateIsSolana, connectKit]
    );
}
