export default {
    language: '繁体中文',
    ConnectButton: {
        connectWallet: '連接錢包',
        wrongNetwork: '網絡錯誤',
    },
    ConnectModal: {
        email: '郵箱',
        phone: '電話',
        google: 'Google',
        apple: 'Apple',
        facebook: 'Facebook',
        microsoft: 'Microsoft',
        linkedin: 'Linkedin',
        github: 'Github',
        discord: 'Discord',
        twitter: 'Twitter',
        twitch: 'Twitch',
        title1: '什麼是錢包',
        title2: '連接錢包',
        tip1: '保護你全部Token和NFT的安全',
        tip2: '您可以管理數位資產，例如發送、接收和顯示。',
        tip3: '一種新的登入管道',
        tip4: '您可以通過社交帳戶登入，加密錢包將自動生成，簡單但很安全。',
        less: '收起',
        more: '更多',
    },
    ConnectForm: {
        loading1: '確認連接',
        loading2: '掃描二維碼',
        copied_to_clipboard: '複製到剪貼板',
        copy_success: '複製成功',
    },
    ChainModal: {
        title: '切換網絡',
        wrong: '檢測到網絡錯誤，請切換或斷開後繼續操作。',
        connected: '已連接',
        confirm: '在錢包中確認',
        disconnect: '斷開連接',
        loading: '加載中',
        switchTip: '您的錢包不支持從此應用切換網絡。 請在錢包中嘗試切換網絡。',
    },
    AccountModal: {
        copied: '已複製',
        logout: '登出',
        wallet: '錢包',
        buy: '購買數位貨幣',
        copy_address: '複製地址',
    },
};
