import type { Auth, JsonRpcRequest } from '@particle-network/auth';
import { addHexPrefix, intToHex, isNullish } from '@particle-network/auth';
import { ChainId, chains } from '@particle-network/chains';
import { Buffer } from 'buffer';
import type { IAuthAdapter } from './types';
import { ProviderError } from './types';

export class AuthEVMAdapter implements IAuthAdapter {
    constructor(private auth: Auth) {
        this.auth = auth;
    }

    public async request(request: Partial<JsonRpcRequest>): Promise<any> {
        if (request.method === 'eth_requestAccounts' || request.method === 'eth_accounts') {
            let wallet = this.auth.getWallet();
            if (wallet) {
                return [wallet.public_address];
            }
            await this.auth.login();
            wallet = this.auth.getWallet();
            if (wallet) {
                return [wallet.public_address];
            } else {
                throw new Error('Create wallet failed');
            }
        } else if (request.method === 'eth_chainId') {
            return intToHex(this.auth.getChainId());
        } else if (request.method === 'eth_sendTransaction') {
            if (request.params && request.params instanceof Array && request.params[0]) {
                const txData = request.params[0];
                if (isNullish(txData.type)) {
                    if (chains.isChainSupportEIP1559(this.auth.getChain())) {
                        // set transaction default type "0x2", EIP1559.
                        txData.type = '0x2';
                    } else {
                        txData.type = '0x0';
                    }
                }
                if (isNullish(txData.chainId)) {
                    txData.chainId = intToHex(this.auth.getChainId());
                }
                if (isNullish(txData.nonce)) {
                    txData.nonce = '0x0';
                }
                if (isNullish(txData.data)) {
                    txData.data = '0x';
                }

                return this.auth.sendTransaction(this.legacyToString(request.params[0]));
            } else {
                return Promise.reject(ProviderError.paramsError());
            }
        } else if (
            request.method === 'eth_signTypedData_v3' ||
            request.method === 'eth_signTypedData_v4' ||
            request.method === 'eth_signTypedData_v4_uniq'
        ) {
            if (request.params && request.params instanceof Array && request.params.length >= 2) {
                let typedData = request.params[1];
                if (typeof typedData === 'string' && !typedData.startsWith('0x')) {
                    typedData = addHexPrefix(Buffer.from(typedData).toString('hex'));
                }
                return this.auth.sign(request.method, this.legacyToString(typedData));
            } else {
                return Promise.reject(ProviderError.paramsError());
            }
        } else if (request.method === 'eth_signTypedData' || request.method === 'eth_signTypedData_v1') {
            if (request.params && request.params instanceof Array && request.params[0]) {
                let typedData = request.params[0];
                if (typeof typedData === 'string' && !typedData.startsWith('0x')) {
                    typedData = addHexPrefix(Buffer.from(typedData).toString('hex'));
                }
                return this.auth.sign(request.method, this.legacyToString(typedData));
            } else {
                return Promise.reject(ProviderError.paramsError());
            }
        } else if (request.method === 'personal_sign' || request.method === 'personal_sign_uniq') {
            if (request.params && request.params instanceof Array && request.params[0]) {
                return this.auth.sign(request.method, this.legacyToString(request.params[0]));
            } else {
                return Promise.reject(ProviderError.paramsError());
            }
        } else if (request.method === 'wallet_switchEthereumChain') {
            if (request.params && request.params instanceof Array && request.params[0] && request.params[0].chainId) {
                const chainId = Number(request.params[0].chainId) as ChainId;
                const chain = chains.getEVMChainInfoById(chainId);
                // for each key
                if (chain) {
                    await this.auth.switchChain(chain);
                    return Promise.resolve(null);
                }
                return Promise.reject(ProviderError.unsupportedChain());
            } else {
                return Promise.reject(ProviderError.paramsError());
            }
        } else {
            return Promise.reject(ProviderError.unsupportedMethod());
        }
    }

    private legacyToString(params: unknown): string {
        let message;
        if (typeof params === 'number') {
            message = addHexPrefix(params.toString(16));
        } else if (typeof params === 'string') {
            if (params.toString().startsWith('0x')) {
                message = params;
            } else {
                message = addHexPrefix(Buffer.from(params).toString('hex'));
            }
        } else {
            message = addHexPrefix(Buffer.from(JSON.stringify(params)).toString('hex'));
        }
        return message;
    }
}
