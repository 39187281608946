export default {
    language: '韩文',
    ConnectButton: {
        connectWallet: '지갑 연결',
        wrongNetwork: '네트워크 오류',
    },
    ConnectModal: {
        email: '메일박스',
        phone: '전화기',
        google: 'Google',
        apple: 'Apple',
        facebook: 'Facebook',
        microsoft: 'Microsoft',
        linkedin: 'Linkedin',
        github: 'Github',
        discord: 'Discord',
        twitter: 'Twitter',
        twitch: 'Twitch',
        title1: '지갑이 뭐예요',
        title2: '지갑 연결',
        tip1: '모든 Token과 NFT를 안전하게 지켜드립니다.',
        tip2: '전송, 수신 및 표시와 같은 디지털 자산을 관리할 수 있습니다.',
        tip3: '새로운 로그인 방법',
        tip4: '소셜 계정을 통해 로그인할 수 있으며 암호화 지갑이 자동으로 생성되며 간단하지만 안전합니다.',
        less: '집어치우다',
        more: '추가',
    },
    ConnectForm: {
        loading1: '접속 확인',
        loading2: 'QR코드 스캔',
        copied_to_clipboard: '클립보드로 복사',
        copy_success: '복제 성공',
    },
    ChainModal: {
        title: '네트워크 전환',
        wrong: '네트워크 오류가 발견되었습니다. 전환하거나 분리한 후 계속하십시오.',
        connected: '접속됨',
        confirm: '지갑에서 확인',
        disconnect: '연결 해제',
        loading: '로드 중',
        switchTip: '지갑은 이 앱에서 네트워크 전환을 지원하지 않습니다.지갑에서 네트워크 전환을 시도하십시오.',
    },
    AccountModal: {
        copied: '복사됨',
        logout: '로그인 종료',
        wallet: '지갑',
        buy: '디지털 화폐 구매',
        copy_address: '주소 복사',
    },
};
