export const shortString = (longValue?: string) => {
    if (!longValue) {
        return '';
    }

    if (longValue.length <= 10) {
        return longValue;
    }
    return `${longValue.slice(0, 5)}...${longValue.slice(longValue.length - 5, longValue.length)}`;
};
